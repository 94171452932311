import React, { useState } from 'react';
import {
    Container, TextField, Button, Typography, Input, FormControl, Backdrop, CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SnackbarMessage from '../components/SnackbarMessage';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json'; // Adjust the path as needed
import translationAR from '../locales/ar/translation.json'; // Adjust the path as needed
import { API_ENDPOINTS } from '../api/apiConfig';

// Initialize i18n
i18n.use(initReactI18next).init({
    resources: {
        en: { translation: translationEN },
        ar: { translation: translationAR },
    },
    lng: 'ar',
    fallbackLng: 'ar',
    interpolation: { escapeValue: false },
});

function CreateAccountOwner() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [form, setForm] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        image: null,
    });
    const [errors, setErrors] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        image: '',
    });
    const [preview, setPreview] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm(prev => ({ ...prev, [name]: value }));
        // Clear errors on input change
        setErrors(prev => ({ ...prev, [name]: '' }));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setForm(prev => ({ ...prev, image: file }));
            previewFile(file);
        } else {
            setForm(prev => ({ ...prev, image: null }));
            setPreview('');
        }
    };

    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPreview(reader.result);
        };
    };

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setForm({
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
            image: null,
        });
        setPreview(null);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const validateForm = () => {
        let valid = true;
        let newErrors = {
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
            image: '',
        };

        if (!form.username.trim()) {
            newErrors.username = t('usernameRequired');
            valid = false;
        }
        if (!form.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            newErrors.email = t('emailValid');
            valid = false;
        }
        if (!form.password) {
            newErrors.password = t('passwordRequired');
            valid = false;
        }
        if (form.password !== form.confirmPassword) {
            newErrors.confirmPassword = t('passwordsDoNotMatch');
            valid = false;
        }
        if (!form.image) {
            newErrors.image = t('imageRequired');
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateForm()) {
            setLoading(true);
            try {


                const formData = new FormData();
                formData.append('name', form.username);
                formData.append('email', form.email);
                formData.append('image', form.image);
                formData.append('KEY', process.env.REACT_APP_API_KEY);

                const response = await fetch(API_ENDPOINTS.uploadImageUser, { // Adjust this URL to your server endpoint
                    method: 'POST',
                    body: formData,
                });
                const responseData = await response.json();
                if (response.ok) {

                    //console.log('Image uploaded successfully:', responseData);
                    const responsenew = await fetch(API_ENDPOINTS.AddUser, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            name: form.username,
                            email: form.email,
                            image: responseData.imageUrl,
                            type: "owner",
                            isActive: true,
                            ListOfDay:[],
                            KEY: process.env.REACT_APP_API_KEY,
                            password:form.password
                        })
                    });
                    if (responsenew.ok) {
                        handleClick();
                    }
                } else {
                    throw new Error(responseData.message || "Failed to upload image");
                }

            } catch (error) {
                console.error('Error submitting form:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5">
                {t('createAccount')}
            </Typography>
            <form onSubmit={handleSubmit} noValidate>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label={t('username')}
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={form.username}
                    onChange={handleChange}
                    error={!!errors.username}
                    helperText={errors.username}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t('emailAddress')}
                    name="email"
                    autoComplete="email"
                    value={form.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('password')}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={form.password}
                    onChange={handleChange}
                    error={!!errors.password}
                    helperText={errors.password}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label={t('confirmPassword')}
                    type="password"
                    id="confirmPassword"
                    value={form.confirmPassword}
                    onChange={handleChange}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                />
                <FormControl fullWidth margin="normal" required error={!!errors.image}>
                    <Input
                        id="image-upload"
                        type="file"
                        inputProps={{ accept: 'image/*' }}
                        onChange={handleImageChange}
                    />
                    {errors.image && <Typography color="error" variant="caption">{errors.image}</Typography>}
                </FormControl>
                {preview && (
                    <img src={preview} alt="Image Preview" style={{ width: '100%', height: 'auto', marginTop: '10px' }} />
                )}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading}
                >
                    {t('createAccountButton')}
                </Button>
            </form>
            <SnackbarMessage
                open={open}
                handleClose={handleClose}
                message={t('doneAddUser')}
                severity="success"
            />
            <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default CreateAccountOwner;
