import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, List, ListItem, ListItemText, CircularProgress, Snackbar, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json'; // Adjust the path as needed
import translationAR from '../locales/ar/translation.json'; // Adjust the path as needed
import { API_ENDPOINTS } from '../api/apiConfig';

// Initialize i18n
i18n.use(initReactI18next).init({
    resources: {
        en: { translation: translationEN },
        ar: { translation: translationAR },
    },
    lng: 'ar', // Default language
    fallbacklng: 'ar',
    interpolation: { escapeValue: false },
});

function SaleFor() {
    const { t, i18n } = useTranslation();
    const [saleForName, setSaleForName] = useState('');
    const [saleFors, setSaleFors] = useState([]);  
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const getData = (url) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({KEY: process.env.REACT_APP_API_KEY})
        })
        .then(response => response.json())
        .then(data => {
            setSaleFors(data); 
        })
        .catch(error => console.error('Error fetching SaleFors:', error));
    };

    useEffect(() => {
        getData(API_ENDPOINTS.saleFor);
    }, []);

    const handleSaleForNameChange = (event) => {
        setSaleForName(event.target.value);
    };

    const handleSaveSaleFor = async () => {
        if (!saleForName.trim()) {
            setError(t('saleForNameRequired'));
            return;
        }
        setLoading(true);
        setError('');

        fetch(API_ENDPOINTS.createSaleFor, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                saleFor: saleForName,
                KEY: process.env.REACT_APP_API_KEY
            })
        })
        .then(response => response.json())
        .then(data => {
            setSaleForName("");
            setSaleFors(prevSaleFors => [...prevSaleFors, data]); 
            setOpenSnackbar(true);
        })
        .catch(error => {
            console.error('Error submitting form:', error);
            setError(t('error'));
        })
        .finally(() => setLoading(false));
    };


    return (
        <Box sx={{ mt: 4, mx: 'auto', width: '90%', maxWidth: 500 }}>
           
            <Typography variant="h6">{t('addNewSaleFor')}</Typography>
            <TextField
                label={t('saleForName')}
                variant="outlined"
                fullWidth
                margin="normal"
                value={saleForName}
                onChange={handleSaleForNameChange}
                required
            />
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSaveSaleFor}
                disabled={loading}
                sx={{ mt: 2 }}
            >
                {t('saveSaleFor')}
            </Button>
            {loading && <CircularProgress sx={{ display: 'block', mx: 'auto', my: 2 }} />}
            {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                message={t('newItemAdded')}
            />
            <Typography variant="h6" sx={{ mt: 4 }}>{t('existingSaleFors')}</Typography>
            <List>
                {saleFors.map((saleFor) => (
                    <ListItem key={saleFor.id}>
                        <ListItemText primary={saleFor.saleFor} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

export default SaleFor;
