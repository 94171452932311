import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent, Typography, Box } from '@mui/material';

function Language() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Card>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            {t('changeLanguage')}
          </Typography>
          <Box display="flex" justifyContent="space-around" mt={2}>
            <Button variant="contained" color="primary" onClick={() => changeLanguage('en')}>
              English
            </Button>
            <Button variant="contained" color="secondary" onClick={() => changeLanguage('ar')}>
              العربيه
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Language;
