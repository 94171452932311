import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Container, Typography, Grid, Card, CardContent } from '@mui/material';
import { format, isBefore, isToday } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../api/apiConfig';

const Orders = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const nameUser = location.state?.nameUser;
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(API_ENDPOINTS.orders, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ idUser: id, KEY: process.env.REACT_APP_API_KEY }),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        //console.log(data)
        setOrders(data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, [id]);

 const handleDateClick = (order) => {
  navigate(`/users/${id}/orders/${order.idBillNumber}`, { state: { nameUser, order } });
};

  const getBackgroundColor = (date) => {
    const orderDate = new Date(date);
    if (isBefore(orderDate, new Date()) && !isToday(orderDate)) {
      return 'red';
    }
    return 'white';
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {t('ordersForUser')} {nameUser}
      </Typography>
      <Grid container spacing={4}>
        {orders.map((order, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card
              onClick={() => handleDateClick(order)}
              style={{
                cursor: 'pointer',
                backgroundColor: getBackgroundColor(order.date),
              }}
            >
              <CardContent>
              <Typography variant="h6">{t('idBillNumber')}: {order.idBillNumber }</Typography>
                <Typography variant="h6">{t('orderDate')}: {format(new Date(order.date), 'yyyy-MM-dd')}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Orders;
