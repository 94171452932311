import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import UserProfile from './pages/UserProfile';
import LoginPage from './pages/login';
import { useAuth } from './context/AuthContext';
import AddProduct from './pages/AddProduct';
import AddType from './pages/AddType';
import SaleFor from './pages/saleFor';
import CreateAccount from './pages/RegisterPage';
import WelcomePage from './pages/welcomePage';
import UserList from './pages/allUsers';
import { CssBaseline } from '@mui/material';
import Orders from './pages/ordersUser';
import OrderDetails from './pages/OrderDetails';
import CreateAccountOwner from './pages/RegisterPageOwner';
import AllUsers from './pages/allUsersToEdit';
import { useTranslation } from 'react-i18next';
import Language from './pages/Language';
import SetNewOrder from './pages/setNewOrder';
import AllOrder from './pages/AllOrder';
import DeviceDetection from './pages/DeviceDetection';

function App() {
  const { authenticated } = useAuth();

  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language]);


  return (
    <Router>
       <Navbar />
       <CssBaseline/>
      <div className="container mt-3">
        <Routes>
        <Route path="/device" element={<DeviceDetection />} />

        <Route path="/Language" element={<Language />} />
        {authenticated && (
            <>
              <Route path="/home" element={<HomePage />} />
              <Route path="/users" element={ <UserList />} />
              <Route path="/AllOrder" element={ <AllOrder />} />
              <Route path="/AllUsers" element={ <AllUsers />} />

              <Route path="/users/:id" element={<Orders />} />
              <Route path="/users/:id/orders/:idBillNumber" element={<OrderDetails />} />

              <Route path="/profile" element={<UserProfile />} />
              <Route path="/AddProduct" element={<AddProduct />} />
              <Route path="/type" element={<AddType />} />
              <Route path="/SaleFor" element={<SaleFor />} />
              <Route path="/AddNewCustomer" element={<CreateAccount />} />
              <Route path="/AddNewOwner" element={<CreateAccountOwner />} />
              <Route path="/SetNewOrder" element={<SetNewOrder />} />

              <Route path="*" element={<HomePage />} />
            </>
          )}
          {!authenticated && (
            <>
              <Route path="/login" element={<LoginPage />} />
             
              <Route path="*" element={<WelcomePage />} />
            </>
          )}

        </Routes>
      </div>
    </Router>
  );
}

export default App;
