import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Snackbar,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../api/apiConfig';
import NotificationsIcon from '@mui/icons-material/Notifications';

const UserList = () => {
  const { t, i18n } = useTranslation();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDays, setSelectedDays] = useState([]);
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);  // State for Snackbar open
  const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message

  const daysMap = {
    en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    ar: ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت']
  };

  const getDayInEnglish = (dayInCurrentLang) => {
    const index = daysMap[i18n.language].indexOf(dayInCurrentLang);
    return daysMap['en'][index];
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(API_ENDPOINTS.getAllUser, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY }),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUsers(data);
        setFilteredUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    filterUsers(searchValue, selectedDays);
  };

  const handleDaysChange = (event) => {
    const days = event.target.value;
    setSelectedDays(days);
    filterUsers(searchTerm, days);
  };

  const filterUsers = (nameSearch, daysSearch) => {
    const filtered = users.filter(user => {
      const matchesName = user.name.toLowerCase().includes(nameSearch);
      const selectedDaysInEnglish = daysSearch.map(day => (i18n.language === 'ar' ? getDayInEnglish(day) : day));
      const userDays = user.Days.map(dayObj => dayObj.day.toLowerCase());
      const matchesDays = selectedDaysInEnglish.every(day => userDays.includes(day.toLowerCase()));
      return matchesName && matchesDays;
    });
    setFilteredUsers(filtered);
  };

  const handleCardClick = (user) => {
    navigate(`/users/${user.id}`, { state: { nameUser: user.name } });
  };

  const handleSendNotification = async (event, token) => {
    event.stopPropagation();

    if (!token) {
      setSnackbarMessage(t('notificationNotSent'));
      setSnackbarOpen(true);  // Open Snackbar
      return;
    }
    try {
      const response = await fetch('https://sendnotification-43nxgz234a-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          title: "تحضير طلبية",
          bodyText: "ارجاء تحضير طلبية باسرع وقت لتحضيرها"
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setSnackbarMessage(t('notificationSent'));
    } catch (error) {
      console.error('Error sending notification:', error);
      setSnackbarMessage(t('notificationNotSent'));
    }
    setSnackbarOpen(true);  // Open Snackbar
  };

  const handleCopyEmail = (event, email) => {
    event.stopPropagation();  // Prevent event bubbling to the card
    navigator.clipboard.writeText(email);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <Container xs={5} sx={{ m: 2 }}>
      <Typography variant="h4" gutterBottom>{t('userList')}</Typography>
      <TextField
        fullWidth
        label={t('searchByName')}
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ mb: 4 }}
      />
      <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
        <InputLabel>{t('selectDays')}</InputLabel>
        <Select
          multiple
          value={selectedDays}
          onChange={handleDaysChange}
          label={t('selectDays')}
        >
          {daysMap[i18n.language].map((day) => (
            <MenuItem key={day} value={day}>
              {day}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Grid container spacing={4}>
        {filteredUsers.map((user) => (
          <Grid item key={user.id} xs={12} sm={6} md={4}>
            <Card onClick={() => handleCardClick(user)} style={{ cursor: 'pointer' }}>
              <CardContent>
                <Avatar alt={user.name} src={user.image} sx={{ width: 56, height: 56 }} />
                <Typography variant="h6">{t('name')}: {user.name}</Typography>
                <Typography color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                  {t('email')}: {user.email}
                  <IconButton onClick={(event) => handleCopyEmail(event, user.email)} size="small" sx={{ ml: 1 }}>
                    <ContentCopyIcon />
                  </IconButton>
                </Typography>
                <Typography color="textSecondary">
                  {t('days')}: {user.Days.map(day => daysMap[i18n.language][daysMap['en'].indexOf(day.day)]).join(', ')}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<NotificationsIcon />}
                  onClick={(event) => handleSendNotification(event, user.token)}
                  sx={{ mt: 2 }}
                >
                  {t('sendNotification')}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserList;
