import React from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Grid, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import translationEN from '../locales/en/translation.json';
import translationAR from '../locales/ar/translation.json';

// Initialize i18n
i18n.use(initReactI18next).init({
    resources: {
        en: { translation: translationEN },
        ar: { translation: translationAR },
    },
    lng: 'ar', // Default language
    fallbackLng: 'ar',
    interpolation: { escapeValue: false },
});

const ProductCard = ({ product, onEdit }) => {
    const { t } = useTranslation();

    const activeCircleStyle = {
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: product.isActive ? 'green' : 'red',
        display: 'inline-block',
        marginRight: 10,
    };

    return (
        <Card>
            <CardMedia
                component="img"
                height="230"
                image={product.imageUrl}
                alt={product.name}
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <Box display="flex" alignItems="center">
                            <Box style={activeCircleStyle} />
                            <Typography gutterBottom variant="h5" component="div">
                                {product.name}
                            </Typography>
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                            {product.description}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {t('price')}: <span style={{ textDecoration: product.discount ? 'line-through' : 'none' }}>${product.price}</span> {product.discount && <span>${product.discountPrice}</span>}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {t('amount')}: {product.amount}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="body2" color="text.secondary">
                            {t('type')}: {product.types}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {t('saleFor')}: {product.sale_for}
                        </Typography>
                    </Grid>
                </Grid>
                <IconButton onClick={() => onEdit(product)}>
                    <EditIcon />
                </IconButton>
            </CardContent>
        </Card>
    );
};

export default ProductCard;
