import React, { useEffect, useState } from 'react';
import { Grid, Container, CircularProgress, Alert, TextField, MenuItem, Select, FormControl, InputLabel, Button, Autocomplete } from '@mui/material';
import ProductCard from '../components/ProductCard';
import ProductEditDialog from '../components/ProductEditDialog';
import { useAuth } from '../context/AuthContext';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json';
import translationAR from '../locales/ar/translation.json';
import { API_ENDPOINTS } from '../api/apiConfig';

// Initialize i18n
i18n.use(initReactI18next).init({
    resources: {
        en: { translation: translationEN },
        ar: { translation: translationAR },
    },
    lng: 'ar', // Default language
    fallbackLng: 'ar',
    interpolation: { escapeValue: false },
});

const DisplayProducts = () => {
    const { t, i18n } = useTranslation();
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]); // New state for filtered products
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { userData } = useAuth();
    const [types, setTypes] = useState([]);
    const [saleFors, setSaleFors] = useState([]);
    const [searchType, setSearchType] = useState([]);
    const [searchSaleFor, setSearchSaleFor] = useState('');
    const [searchName, setSearchName] = useState(''); // New state for search by name

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(API_ENDPOINTS.getProducts, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY })
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                setProducts(data);
                setFilteredProducts(data); // Initialize filtered products
            } catch (error) {
                setError(`${t('errorFetchingProducts')} ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        const fetchTypesAndSaleFors = async () => {
            try {
                const typesResponse = await fetch(API_ENDPOINTS.getTypes, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY })
                });
                const saleForsResponse = await fetch(API_ENDPOINTS.getSaleFor, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY })
                });

                if (!typesResponse.ok || !saleForsResponse.ok) {
                    throw new Error('Failed to fetch types or saleFor data.');
                }

                const typesData = await typesResponse.json();
                const saleForsData = await saleForsResponse.json();

                setTypes(typesData);
                setSaleFors(saleForsData);
            } catch (error) {
                setError(`${t('errorFetchingFilters')} ${error.message}`);
            }
        };

        fetchProducts();
        fetchTypesAndSaleFors();

        const intervalId = setInterval(fetchProducts, 30000); // Fetch products every 30 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [t]);

    const handleEdit = (product) => {
        setSelectedProduct(product);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedProduct(null);
    };

    const handleSave = (updatedProduct) => {
        setProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.id === updatedProduct.id ? updatedProduct : product
            )
        );
        handleCloseDialog();
    };

    const handleSearch = () => {
        setLoading(true);
        const fetchFilteredProducts = async () => {
            try {
                const response = await fetch(API_ENDPOINTS.getProducts, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        KEY: process.env.REACT_APP_API_KEY,
                        typeVals: searchType,
                        saleForVal: searchSaleFor,
                    })
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                setProducts(data.message ? [] : data);
                filterProductsByName(data); // Filter products by name after fetching
            } catch (error) {
                setError(`${t('errorFetchingProducts')} ${error.message}`);
            } finally {
                setLoading(false);
            }
        };
        fetchFilteredProducts();
    };

    const filterProductsByName = (productsToFilter) => {
        if (searchName) {
            const filtered = productsToFilter.filter(product =>
                product.name.toLowerCase().includes(searchName.toLowerCase())
            );
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts(productsToFilter);
        }
    };

    useEffect(() => {
        filterProductsByName(products); // Filter products when searchName changes
    }, [searchName, products]);

    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Container>
            <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                label={t('searchByName')}
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)} // Handle search input
            />

            <FormControl fullWidth variant="outlined" margin="normal">
                <Autocomplete
                    multiple
                    options={types.map((type) => type.type)}
                    value={searchType}
                    onChange={(event, newValue) => setSearchType(newValue)}
                    renderInput={(params) => (
                        <TextField {...params} label={t('type')} variant="outlined" />
                    )}
                />
            </FormControl>

            <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>{t('saleFor')}</InputLabel>
                <Select
                    value={searchSaleFor}
                    onChange={(e) => setSearchSaleFor(e.target.value)}
                    label={t('saleFor')}
                >
                    {saleFors.map((saleFor) => (
                        <MenuItem key={saleFor.saleFor} value={saleFor.saleFor}>
                            {saleFor.saleFor}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Button variant="contained" color="primary" onClick={handleSearch}>
                {t('search')}
            </Button>

            <Grid container spacing={4} style={{ marginTop: 20 }}>
                {Array.isArray(filteredProducts) && filteredProducts.map((product, index) => (
                    <Grid item key={product.id} xs={12} sm={6} md={4}>
                        <ProductCard product={product} onEdit={() => handleEdit(product)} />
                    </Grid>
                ))}
            </Grid>

            {selectedProduct && (
                <ProductEditDialog
                    open={isDialogOpen}
                    product={selectedProduct}
                    onClose={handleCloseDialog}
                    onSave={handleSave}
                    datatypes={types}
                    datasetSaleFors={saleFors}
                />
            )}
        </Container>
    );
};

export default DisplayProducts;
