import React, { useEffect } from 'react';

function DeviceDetection() {
  useEffect(() => {
    // Function to detect the device's OS and redirect automatically
    const detectAndRedirect = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        // Redirect to the Android URL
        window.location.href = 'https://play.google.com/store/apps/details?id=online.bonestudio.samer';
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // Redirect to the iOS URL
        window.location.href = 'https://testflight.apple.com/join/keBWefNC';
      } else {
        alert('OS not recognized. This page is designed for Android and iOS devices.');
      }
    };

    // Call the function when the component mounts
    detectAndRedirect();
  }, []);

  return null; // No content to render, as this component only handles redirection
}

export default DeviceDetection;
