import React, { useEffect, useState } from 'react';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword,updateProfile } from "firebase/auth";
import { TextField, Button, Card, CardContent, Typography, Alert, Box, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json'; // Adjust the path as needed
import translationAR from '../locales/ar/translation.json'; // Adjust the path as needed
import { API_ENDPOINTS } from '../api/apiConfig';

import { messaging } from "../auth/firebaseConfig";
import { getToken, onMessage } from "firebase/messaging";
// Initialize i18n
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    ar: { translation: translationAR },
  },
  lng: 'ar', // Default language
  fallbackLng: 'ar',
  interpolation: { escapeValue: false },
});

// Styled components using @emotion/styled
const LoginContainer = styled(Box)({
  marginTop: '5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const StyledCard = styled(Card)({
  width: '100%',
  maxWidth: 600
});

function Login() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  useEffect(() => {
    // Request Notification permission
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.log("Notification permission granted.");

         
        } else {
          console.error("Notification permission denied.");
        }
      } catch (error) {
        console.error("An error occurred while requesting permission:", error);
      }
    };

    requestPermission();
  }, []);

  useEffect(() => {
    // Listen for foreground messages
    onMessage(messaging, (payload) => {
      console.log("Received foreground message: ", payload);
      alert(`Notification received: ${payload.notification.title}`);
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    try {
      //console.log("ApiEndpoints.Login");
      //console.log(`${API_ENDPOINTS.Login}${email}`);
      
      const response = await fetch(`${API_ENDPOINTS.Login}${email}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY })
      });

      if (response.status === 200) {
        const myUser = await response.json();
        if (myUser.type === "owner") {
          await signInWithEmailAndPasswordHandler(myUser);
        } else {
          setDialogMessage('Login Error');
          setDialogOpen(true);
        }
      } else {
        const errorData = await response.text();
        console.error(`Error: ${errorData}`);
        setDialogMessage(errorData);
        setDialogOpen(true);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
      setDialogMessage(error.toString());
      setDialogOpen(true);
    }
  };

  const signInWithEmailAndPasswordHandler = async (myUser) => {
    const auth = getAuth();

    try {
      await signInWithEmailAndPassword(auth, email, password);
       // Get FCM Token
       const registration = await navigator.serviceWorker.ready;
       const token = await getToken(messaging, {
        vapidKey: "BKvwy1K_ucNTXPprvkMjvd20w6SJD47FE_ky1L7ET_YzYSeAG2Kapi7gBOePpbhhukZfVNyEiFInqzzHjuFjRGQ",
        serviceWorkerRegistration: registration,
      });

      if (token) {
        console.log("FCM Token:", token);
        try {
          const response = await fetch(`${API_ENDPOINTS.usersUpdateId}/${myUser.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token: token,
              KEY: process.env.REACT_APP_API_KEY
    
            }),
          });
          if (!response.ok) {
            console.error('Error updating order status: ',response.json());
            console.error('Error updating order status: ',response.ok);
            throw new Error('Network response was not ok');
          }
          console.error('Error updating order status: Done');
          //setOrderDetails(updatedOrderDetails);
        } catch (error) {
          console.error('Error updating order status:', error);
        } finally {
          //setLoading(false);
        }
      }
      // Navigate to home page or perform other actions after successful login
    } catch (error) {
      console.log("error   ",error);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <LoginContainer>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <StyledCard>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                {t('login')}
              </Typography>
              {error && <Alert severity="error">{error}</Alert>}
              <form onSubmit={handleSubmit}>
                <TextField
                  label={t('emailAddress')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                <TextField
                  label={t('password')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                />
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  {t('loginButton')}
                </Button>
              </form>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{t('loginErrorTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
    </LoginContainer>
  );
}

export default Login;
