import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CardActions,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Chip,
  Box,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from '@mui/lab/Autocomplete';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json'; // Adjust the path as needed
import translationAR from '../locales/ar/translation.json'; // Adjust the path as needed
import { API_ENDPOINTS } from '../api/apiConfig';

// Initialize i18n
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    ar: { translation: translationAR },
  },
  lng: 'ar', // Default language
  fallbackLng: 'ar',
  interpolation: { escapeValue: false },
});

const weekdaysEN = [
  { label: 'Sunday' },
  { label: 'Monday' },
  { label: 'Tuesday' },
  { label: 'Wednesday' },
  { label: 'Thursday' },
  { label: 'Friday' },
  { label: 'Saturday' }
];

const weekdaysAR = [
  { label: 'الأحد' },
  { label: 'الإثنين' },
  { label: 'الثلاثاء' },
  { label: 'الأربعاء' },
  { label: 'الخميس' },
  { label: 'الجمعة' },
  { label: 'السبت' }
];

const dayMapping = {
  'Sunday': 'الأحد',
  'Monday': 'الإثنين',
  'Tuesday': 'الثلاثاء',
  'Wednesday': 'الأربعاء',
  'Thursday': 'الخميس',
  'Friday': 'الجمعة',
  'Saturday': 'السبت',
};

const AllUsers = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [form, setForm] = useState({ weekdays: [], localDay: '' });
  const [errors, setErrors] = useState({});
  const [selectedDay, setSelectedDay] = useState(''); // State for selected day
  const [notificationMessage, setNotificationMessage] = useState(''); // State for notification message
  const navigate = useNavigate();

  const weekdays = i18n.language === 'ar' ? weekdaysAR : weekdaysEN;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(API_ENDPOINTS.getAllUser, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY }),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleEditClick = (user) => {
    setEditingUser(user);
    setForm({
      weekdays: user.Days.map(day => weekdaysEN.find(weekday => weekday.label === day.day)),
      localDay: user.localDay || '', // Adjust according to your data structure
      name: user.name,
      email: user.email,
      image: user.image,
    });
    setIsDialogOpen(true);
  };

  const handleWeekdaysChange = (event, newValue) => {
    setForm({ ...form, weekdays: newValue });
  };

  const handleLocalDayChange = (event) => {
    setForm({ ...form, localDay: event.target.value });
  };

  const handleToggleActive = async (id, isActive) => {
    try {
      const response = await fetch(`${API_ENDPOINTS.EditDataUser}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          isActive,
          KEY: process.env.REACT_APP_API_KEY,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const updatedUser = await response.json();
      setUsers(users.map((user) => (user.id === id ? updatedUser : user)));
    } catch (error) {
      console.error('Error toggling active status:', error);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setEditingUser(null);
  };

  const handleSave = async () => {
    var ListOfDay = form.weekdays.map(day => (day.label));

    var Days = form.weekdays.map(day => ({ day: day.label }));

    try {
      const response = await fetch(`${API_ENDPOINTS.EditDataUser}${editingUser.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...editingUser,
          name: form.name,
          email: form.email,
          image: form.image,
          ListOfDay: ListOfDay,
          localDay: form.localDay, // Add localDay to the payload
          KEY: process.env.REACT_APP_API_KEY,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const updatedUser = await response.json();
      updatedUser['Days'] = Days;
      setUsers(users.map((user) => (user.id === updatedUser.id ? updatedUser : user)));
      handleDialogClose();
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleSendNotification = async (token) => {
    if (!token) {
      setNotificationMessage(t('notificationNotSent'));
      return;
    }
    try {
      const response = await fetch('https://sendnotification-43nxgz234a-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          title:"تحضير طلبية",
          bodyText:"ارجاء تحضير طلبية باسرع وقت لتحضيرها"
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setNotificationMessage(t('notificationSent'));
    } catch (error) {
      console.error('Error sending notification:', error);
      setNotificationMessage(t('notificationNotSent'));
    }
  };

  const handleSelectedDayChange = (event) => {
    setSelectedDay(event.target.value);
  };

  const getDayInEnglish = (day) => {
    if (i18n.language === 'ar') {
      return Object.keys(dayMapping).find(key => dayMapping[key] === day) || day;
    }
    return day;
  };

  const filteredUsers = selectedDay
    ? users.filter((user) => user.Days.some(day => day.day === getDayInEnglish(selectedDay)))
    : users;

  return (
    <Container xs={5} sx={{ m: 2 }}>
      <FormControl fullWidth margin="normal">
        <Select
          value={selectedDay}
          onChange={handleSelectedDayChange}
          displayEmpty
        >
          <MenuItem value="">
            {t('selectDay')}
          </MenuItem>
          {weekdays.map((day) => (
            <MenuItem key={day.label} value={day.label}>
              {day.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid container spacing={4}>
        {filteredUsers.map((user) => (
          <Grid item key={user.id} xs={12} sm={6} md={4}>
            <Card style={{ cursor: 'pointer' }}>
              <CardContent>
                <Avatar alt={user.name} src={user.image} sx={{ width: 56, height: 56 }} />
                <Typography variant="h6">{user.name}</Typography>
                <Typography color="textSecondary">{user.email}</Typography>
                <Typography color="textSecondary">
                  {user.isActive ? t('active') : t('inactive')}
                </Typography>
                <Box mt={2}>
                  {(user.Days || []).map((day, index) => (
                    <Chip key={index} label={i18n.language === 'ar' ? dayMapping[day.day] : day.day} sx={{ mr: 1, mb: 1 }} />
                  ))}
                </Box>
              </CardContent>
              <CardActions>
                <Button onClick={() => handleToggleActive(user.id, !user.isActive)}>
                  {user.isActive ? t('deactivate') : t('activate')}
                </Button>
                <IconButton onClick={() => handleEditClick(user)}>
                  <EditIcon />
                </IconButton>
                <Button onClick={() =>
                  {
                     handleSendNotification(user.token)
                    }
                  }>
                  {t('sendNotification')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {editingUser && (
        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
          <DialogTitle>{t('editUser')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={t('name')}
              type="text"
              fullWidth
              value={form.name}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
            <TextField
              margin="dense"
              label={t('email')}
              type="email"
              fullWidth
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
            <TextField
              margin="dense"
              label={t('imageURL')}
              type="text"
              fullWidth
              value={form.image}
              onChange={(e) => setForm({ ...form, image: e.target.value })}
            />
            <FormControl fullWidth margin="normal" required error={!!errors.weekdays}>
              <Autocomplete
                multiple
                options={weekdaysEN}
                getOptionLabel={(option) => option.label}
                value={form.weekdays}
                onChange={handleWeekdaysChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t('weekdays')}
                    placeholder={t('selectWeekdays')}
                    error={!!errors.weekdays}
                    helperText={errors.weekdays}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <Select
                value={form.localDay}
                onChange={handleLocalDayChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  {t('selectLocalDay')}
                </MenuItem>
                {weekdays.map((day) => (
                  <MenuItem key={day.label} value={day.label}>
                    {day.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>{t('cancel')}</Button>
            <Button onClick={handleSave}>{t('save')}</Button>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar
        open={!!notificationMessage}
        autoHideDuration={6000}
        onClose={() => setNotificationMessage('')}
      >
        <Alert onClose={() => setNotificationMessage('')} severity="info" sx={{ width: '100%' }}>
          {notificationMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AllUsers;
