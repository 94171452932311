import React, { useState, useEffect, useRef } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    FormControlLabel,
    Switch,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { blobToURL, fromBlob } from 'image-resize-compress';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import translationEN from '../locales/en/translation.json';
import translationAR from '../locales/ar/translation.json';
import { API_ENDPOINTS } from '../api/apiConfig';

// Initialize i18n
i18n.use(initReactI18next).init({
    resources: {
        en: { translation: translationEN },
        ar: { translation: translationAR },
    },
    lng: 'ar', // Default language
    fallbackLng: 'ar',
    interpolation: { escapeValue: false },
});

const ProductEditDialog = ({ open, product, onClose, onSave, datatypes, datasetSaleFors }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({ ...product, types: product.types || [], sale_for: product.sale_for || '' });
    const [imagePreview, setImagePreview] = useState(product.imageUrl);
    const [types, setTypes] = useState(datatypes);
    const [saleFors, setSaleFors] = useState(datasetSaleFors);
    const prevTypesRef = useRef(product.types || []);

    useEffect(() => {
        setFormData({ ...product, types: product.types || [], sale_for: product.sale_for || '' });
        setImagePreview(product.imageUrl);
        setTypes(datatypes);
        setSaleFors(datasetSaleFors);
        prevTypesRef.current = product.types.split(", ") || [];
    }, [product, datatypes, datasetSaleFors]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleAutocompleteChange = async (event, newValue) => {
        const oldTypes = prevTypesRef.current;
        const newTypes = newValue.map(option => option.type);
        const addedTypes = newTypes.filter(type => !oldTypes.includes(type));
        const removedTypes = oldTypes.filter(type => !newTypes.includes(type));

        if (addedTypes.length > 0) {
            const addedType = types.find(type => type.type === addedTypes[0]);
            if (addedType) {
                try {
                    await handleAddType(addedType.id);
                } catch (error) {
                    console.error(error.message);
                    return;
                }
            }
        }

        if (removedTypes.length > 0) {
            const removedType = types.find(type => type.type === removedTypes[0]);
            if (removedType) {
                try {
                    await handleRemoveType(removedType.id);
                } catch (error) {
                    console.error(error.message);
                    return;
                }
            }
        }

        setFormData((prevData) => ({
            ...prevData,
            types: newTypes
        }));
        prevTypesRef.current = newTypes;
    };

    const handleAddType = async (type) => {
        try {
            const response = await fetch(API_ENDPOINTS.productTypes, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    idType:type, 
                    idProduct: product.id, 
                    KEY: process.env.REACT_APP_API_KEY
                 })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! Status: ${response.status} - ${errorText}`);
            }

            const data = await response.json();
            setTypes(prevTypes => [...prevTypes, data]);
        } catch (error) {
            console.error(error.message);
            throw error;
        }
    };

    const handleRemoveType = async (typeId) => {
        try {
            const response = await fetch(`${API_ENDPOINTS.deleteType}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ idType: typeId, idProduct: product.id, KEY: process.env.REACT_APP_API_KEY })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! Status: ${response.status} - ${errorText}`);
            }
        } catch (error) {
            console.error(error.message);
            throw error;
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const quality = 80;
            const width = 0;
            const height = 0;
            const format = 'webp';
            
            fromBlob(file, quality, width, height, format).then((blob) => {
                blobToURL(blob).then(async (url) => {
                    const formData = new FormData();
                    formData.append('email', product.email);
                    formData.append('image', blob);
                    formData.append('KEY', process.env.REACT_APP_API_KEY);

                    
                    try {
                        const uploadResponse = await fetch(API_ENDPOINTS.imageChange, {
                            method: 'POST',
                            body: formData,
                        });

                        if (!uploadResponse.ok) {
                            throw new Error(`Upload failed! Status: ${uploadResponse.status}`);
                        }

                        const uploadData = await uploadResponse.json();
                        const updatedImageUrl = uploadData.imageUrl;
                        setFormData((prevFormData) => ({ ...prevFormData, imageUrl: updatedImageUrl }));
                        setImagePreview(updatedImageUrl);

                        const updateResponse = await fetch(`${API_ENDPOINTS.editProduct}/${product.id}`, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ imageUrl: updatedImageUrl, KEY: process.env.REACT_APP_API_KEY })
                        });

                        if (!updateResponse.ok) {
                            throw new Error(`Update failed! Status: ${updateResponse.status}`);
                        }

                        onSave(formData);
                    } catch (error) {
                        console.error('Error during image upload or update:', error);
                    }
                });
            });
        }
    };

    const handleSubmit = async () => {
       
        try {
            const updatedFormData = {
                ...formData,
                types: Array.isArray(formData.types) ? formData.types : formData.types.split(',').map(type => type.trim()),
                KEY: process.env.REACT_APP_API_KEY
            };

            const response = await fetch(`${API_ENDPOINTS.editProduct}/${product.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedFormData)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! Status: ${response.status} - ${errorText}`);
            }

            updatedFormData.KEY = "";
            const data = await response.json();
            onSave(updatedFormData);
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleSelectChange = async(e) => {
        const { name, value } = e.target;
        const selectedSaleFor = saleFors.find(saleFor => saleFor.saleFor === value);

        try {
            const response = await fetch(`${API_ENDPOINTS.updateSaleFor}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ idSaleFor: selectedSaleFor.id, idProduct: product.id, KEY: process.env.REACT_APP_API_KEY })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! Status: ${response.status} - ${errorText}`);
            }
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                sale_for_id: selectedSaleFor ? selectedSaleFor.id : ''
            }));
        } catch (error) {
            console.error(error.message);
            throw error;
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('editProduct')}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    name="name"
                    label={t('name')}
                    type="text"
                    fullWidth
                    value={formData.name || ''}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="description"
                    label={t('description')}
                    type="text"
                    fullWidth
                    value={formData.description || ''}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="price"
                    label={t('price')}
                    type="number"
                    fullWidth
                    value={formData.price || ''}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="amount"
                    label={t('amount')}
                    type="number"
                    fullWidth
                    value={formData.amount || ''}
                    onChange={handleChange}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData.isActive || false}
                            onChange={handleChange}
                            name="isActive"
                        />
                    }
                    label={t('active')}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={formData.discount || false}
                            onChange={handleChange}
                            name="discount"
                        />
                    }
                    label={t('discount')}
                />
                <TextField
                    margin="dense"
                    name="discountPrice"
                    label={t('discountPrice')}
                    type="number"
                    fullWidth
                    value={formData.discountPrice || ''}
                    onChange={handleChange}
                    disabled={!formData.discount}
                />
                <Autocomplete
                    multiple
                    options={types}
                    getOptionLabel={(option) => option.type}
                    value={types.filter(type => formData.types.includes(type.type))}
                    onChange={handleAutocompleteChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={t('type')}
                            placeholder={t('selectTypes')}
                        />
                    )}
                />
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel>{t('saleFor')}</InputLabel>
                    <Select
                        name="sale_for"
                        value={formData.sale_for || ''}
                        onChange={handleSelectChange}
                        label={t('saleFor')}
                    >
                        {saleFors.map((saleFor) => (
                            <MenuItem key={saleFor.id} value={saleFor.saleFor}>
                                {saleFor.saleFor}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    type="file"
                    onChange={handleImageChange}
                />
                <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span" startIcon={<PhotoCamera />}>
                        {t('uploadImage')}
                    </Button>
                </label>
                {imagePreview && <img src={imagePreview} alt="Product Preview" style={{ marginTop: '10px', width: '100%' }} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('cancel')}</Button>
                <Button onClick={handleSubmit} color="primary">{t('save')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProductEditDialog;
