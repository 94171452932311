import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext'; // Ensure correct path
import { getAuth, updateProfile } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Card, CardContent, TextField, Button, Typography, Box, CircularProgress, Alert, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json'; // Adjust the path as needed
import translationAR from '../locales/ar/translation.json'; // Adjust the path as needed
import { API_ENDPOINTS } from '../api/apiConfig';

// Initialize i18n
i18n.use(initReactI18next).init({
    resources: {
        en: { translation: translationEN },
        ar: { translation: translationAR },
    },
    lng: 'ar', // Default language
    fallbacklng: 'ar',
    interpolation: { escapeValue: false },
});

function UserProfile() {
    const { t, i18n } = useTranslation();
    const { currentUser,userData } = useAuth();
    const [name, setName] = useState(currentUser.displayName || '');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [imagePreviewUrl, setImagePreviewUrl] = useState(currentUser.photoURL || '');

    useEffect(()=>{
        //console.log(currentUser);
    })
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setFile(null);
            setImagePreviewUrl('');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            if (file) {
                const formData = new FormData();
                formData.append('name', name);
                formData.append('email', currentUser.email);
                formData.append('image', file);
                formData.append('KEY', process.env.REACT_APP_API_KEY);
                const response = await fetch(API_ENDPOINTS.uploadImageUser, { // Adjust this URL to your server endpoint
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const responseData = await response.json();
                    await updateProfile(currentUser, { displayName: name, photoURL:responseData.imageUrl });
                    setImagePreviewUrl(responseData.imageUrl);  


                    const userResponse = await fetch(`${API_ENDPOINTS.EditDataUser}/${userData.id}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            name: name,
                            email: currentUser.email,
                            image: responseData.imageUrl,
                            ListOfDay: [],
                            KEY: process.env.REACT_APP_API_KEY,
                        })
                    });
                }
            } else {
                await updateProfile(currentUser, { displayName: name });
            }

            setLoading(false);
            alert(t('profileUpdatedSuccessfully'));
        } catch (error) {
            setError(`${t('failedToUpdateProfile')}: ${error.message}`);
            setLoading(false);
        }
    };


    return (
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Card sx={{ maxWidth: 600, width: '100%' }}>
                <CardContent>
                    <Typography variant="h5" component="div">{t('userProfile')}</Typography>
                    {error && <Alert severity="error">{error}</Alert>}
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label={t('name')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            required
                        />
                        <TextField
                            label={t('emaildisabled')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={currentUser.email}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                component="label"
                                fullWidth
                            >
                                {t('uploadProfileImage')}
                                <input
                                    type="file"
                                    hidden
                                    onChange={handleFileChange}
                                />
                            </Button>
                        </Box>
                        {imagePreviewUrl && (
                            <Box mt={2} display="flex" justifyContent="center">
                                <img src={imagePreviewUrl} alt="Profile" style={{ maxWidth: '100%', maxHeight: 150 }} />
                            </Box>
                        )}
                        <Box mt={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                fullWidth
                            >
                                {loading ? <CircularProgress size={24} /> : t('updateProfile')}
                            </Button>
                        </Box>
                    </form>
                </CardContent>
            </Card>
        </Box>
    );
}

export default UserProfile;
