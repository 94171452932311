import React, { useState } from 'react';
import { Container, Typography, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json'; // Adjust the path as needed
import translationAR from '../locales/ar/translation.json'; // Adjust the path as needed

// Initialize i18n
i18n.use(initReactI18next).init({
    resources: {
        en: { translation: translationEN },
        ar: { translation: translationAR },
    },
    lng: 'ar', // Default language
    fallbacklng: 'ar',
    interpolation: { escapeValue: false },
});

const WelcomePage = () => {
    const { t, i18n } = useTranslation();

    return (
        <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '100px' }}>
           
            <Box mb={4}>
                <Typography variant="h2" component="h1" gutterBottom>
                    {t('welcomeToShopEasy')}
                </Typography>
                <Typography variant="h5" component="p" gutterBottom>
                    {t('discoverDeals')}
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    {t('signUpForOffers')}
                </Typography>
            </Box>
        </Container>
    );
};

export default WelcomePage;
