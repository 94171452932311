import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from '../auth/firebaseConfig'; // Adjust the import path to where your Firebase app is initialized
import { API_ENDPOINTS } from '../api/apiConfig';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [userData, setUserData] = useState({}); // Additional user data
    const [loading, setLoading] = useState(true);

    const updateUser = useCallback((data) => {
        setUserData(data); // Update user data in state
    }, []);

    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            if (user) {
                fetch(`${API_ENDPOINTS.getUsersPyEmail}/${user.email}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({KEY: process.env.REACT_APP_API_KEY }),
                })
                    .then(response => response.json())
                    .then(data => {
                        //console.log("data");
                        //console.log(data);
                        updateUser(data);
                        setLoading(false);
                    })
                    .catch(error => console.error('Error fetching user data:', error));
            } else {
                setLoading(false);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [updateUser]);

    return (
        <AuthContext.Provider value={{ currentUser, userData, authenticated: !!currentUser, updateUser }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
