import React from 'react';
import { Container, Typography } from '@mui/material';
import DisplayProducts from './DisplayProducts';

function HomePage() {
  return (
    <Container sx={{ mt: 5 }}>
     <DisplayProducts />
    </Container>
  );
}

export default HomePage;
