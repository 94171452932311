import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, List, ListItem, ListItemText, CircularProgress, Snackbar } from '@mui/material';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json'; // Adjust the path as needed
import translationAR from '../locales/ar/translation.json'; // Adjust the path as needed
import { API_ENDPOINTS } from '../api/apiConfig';

// Initialize i18n
i18n.use(initReactI18next).init({
    resources: {
        en: { translation: translationEN },
        ar: { translation: translationAR },
    },
    lng: 'ar', // Default language
    fallbacklng: 'ar',
    interpolation: { escapeValue: false },
});

function AddType() {
    const { t, i18n } = useTranslation();
    const [typeName, setTypeName] = useState('');
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const getData = () => {
        fetch(API_ENDPOINTS.getTypes, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body:JSON.stringify({ KEY: process.env.REACT_APP_API_KEY })
        })
        .then(response => response.json())
        .then(data => setTypes(data))
        .catch(error => console.error('Error fetching types:', error));
    }

    useEffect(() => {
        getData();
    }, []);

    const handleTypeNameChange = (event) => {
        setTypeName(event.target.value);
    };

    const handleSaveType = async () => {
        if (!typeName.trim()) {
            setError(t('typeName') + ' ' + t('error')); // Use translation for error message
            return;
        }
        setLoading(true);
        setError('');
        fetch(API_ENDPOINTS.createTypes, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ type: typeName ,KEY: process.env.REACT_APP_API_KEY }),
        })
        .then(response => response.json())
        .then(data => {
            setTypeName("");
            setTypes([...types, data]);
            setOpenSnackbar(true);
        })
        .catch(error => {
            console.error('Error submitting form:', error);
            setError(t('error'));
        })
        .finally(() => setLoading(false));
    };

    return (
        <Box sx={{ mt: 4, mx: 'auto', width: '90%', maxWidth: 500 }}>
            <Typography variant="h6">{t('addType')}</Typography>
            <TextField
                label={t('typeName')}
                variant="outlined"
                fullWidth
                margin="normal"
                value={typeName}
                onChange={handleTypeNameChange}
                required
            />
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSaveType}
                disabled={loading}
                sx={{ mt: 2 }}
            >
                {t('saveType')}
            </Button>
            {loading && <CircularProgress sx={{ display: 'block', mx: 'auto', my: 2 }} />}
            {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                message={t('newTypeAdded')}
            />
            <Typography variant="h6" sx={{ mt: 4 }}>{t('existingTypes')}</Typography>
            <List>
                {types.map((type) => (
                    <ListItem key={type.id}>
                        <ListItemText primary={type.type} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

export default AddType;
